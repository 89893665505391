<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div class="mt-4" style="border-left: 1px solid #878E99; height:1.5rem" />

      <div style="width:auto">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3 pr-3">
          February 2021 Pay Cycle
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height:100%" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6 ">
          <div class="border rounded border-romanSilver border-dashed p-3 ">
            <p class="font-bold mb-8">
              Payrun Information
            </p>
            <div class="flex w-full gap-3">
              <card class=" w-2/12 p-2">
                <p class="uppercase text-chartPurple font-extrabold text-sm">
                  TXN Date
                </p>
                <p class="text-sm uppercase">
                  XXXXXX
                </p>
              </card>
              <card class="w-2/12 p-2">
                <p class="uppercase text-flame  font-extrabold text-sm">
                  payment ref no
                </p>
                <p class="text-sm uppercase">
                  XXXXXX
                </p>
              </card>
              <card class="w-4/12 p-2">
                <p class="uppercase text-blueCrayola font-extrabold text-sm">
                  Pay Cycle
                </p>
                <p class="text-sm uppercase">
                  Regular Pay - FEBRUARY 2021 Pay Cycle
                </p>
              </card>
              <card class="w-4/12 p-2">
                <p class="uppercase text-mediumSeaGreen font-extrabold text-sm">
                  payrun id
                </p>
                <p class="text-sm uppercase">
                  XXXXXX
                </p>
              </card>
            </div>
          </div>

          <div class="flex justify-between mt-5">
            <div class="flex gap-5">
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  bank name
                </p>
                <p
                  class="
                  text-lg text-darkPurple font-bold
                  uppercase"
                >
                  Access Bank Plc
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  account no
                </p>
                <p
                  class="
                  text-lg text-darkPurple font-bold
                  uppercase"
                >
                  0123456789
                </p>
              </card>
            </div>
            <div class="flex items-center">
              <Button
                background-color="none"
                class="text-white mr-5 rounded"
                :background="appearance.buttonColor || defaultBTNColor"
                border="1px solid #F15A29"
                v-if="status === 'approved'"
                @click="edit('paid')"
              >
                Pay
              </Button>
              <Button
                background-color="none"
                class="text-white mr-5 rounded"
                :background="appearance.buttonColor || defaultBTNColor"
                border="1px solid #F15A29"
                v-if="status === 'review'"
                @click="edit('approved')"
              >
                Approve Payrun
              </Button>
              <Button
                background-color="none"
                class="text-white mr-5 rounded"
                :background="appearance.buttonColor || defaultBTNColor"
                border="1px solid #F15A29"
                v-if="status === 'submit'"
              >
                Submit Payrun
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                v-if="status === 'draft'"
                @click="deletePayrun($route.params.id)"
              >
                Delete Payrun
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                v-if="status === 'review'"
                @click="edit('disapproved')"
              >
                Reject Payrun
              </Button>
            </div>
          </div>
          <div class="border w-full bg-romanSilver mt-8" />
        </card>

        <card class="mt-6 p-5">
          <template v-slot:footer>
            <card-footer />
          </template>
        </card>

        <Table
          :headers="headers"
          :items="tableData"
          style="width:100%; height:auto"
          class="w-full"
          :has-checkbox="true"
          aria-label="payCycle table"
          has-number
          :loading="false"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.name" style="width: 130px" class="pt-3 pb-3 flex">
              <img
                v-if="item.data.name"
                class="mr-2 inline-block float-left"
                style="width: 30px; height: 30px"
                src="@/assets/logo.png"
                alt="image"
              />
              <div class="w-auto flex flex-col break-words ">
                <p class="text-sm font-semibold text-darkPurple">
                  {{ item.data.name }}
                </p>
              </div>
            </div>
            <span v-if="item.function">
              {{ item.data.function }}
            </span>
            <span v-if="item.regularEarnings">
              {{ item.data.regularEarnings }}
            </span>
            <span v-if="item.variableEarnings">
              {{ item.data.variableEarnings }}
            </span>
            <span v-if="item.grossEarnings">
              {{ item.data.grossEarnings }}
            </span>
            <span v-if="item.pensionContribution">
              {{ item.data.pensionContribution }}
            </span>
            <span v-if="item.monthlyPAYE">
              {{ item.data.monthlyPAYE }}
            </span>
            <span v-if="item.netPay">
              {{ item.data.netPay }}
            </span>
            <span v-if="item.status">
              <span class="partial py-1 px-10">
                {{ item.data.status }}
              </span>
            </span>
            <span v-if="item.id">
              <div
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'ViewPayrunCycle'
                  })
                "
              >
                <icon
                  icon-name="icon-eye"
                  class-name="text-blueCrayola"
                  size="xs"
                />
              </div>
            </span>
          </template>
        </Table>
      </div>
    </div>
    <RightSideBar
      v-if="openModal"
      @submit="submit"
      @close="openModal = false"
      button-class="bg-dynamicBackBtn text-white"
      close-button
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          Action
        </p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple text-l mb-2">
          Confirm your action here. You can add a comment or remark based on
          what action is taken.
        </p>
        <p class="text-jet text-l mb-2">
          You can change selected action here:
        </p>
        <RadioButton
          :options="categoryArray"
          col-span="col-span-12"
          class="m-4  text-darkPurple"
          row-gap="gap-y-3"
          v-model="ActionType"
        />
      </template>
      <p class="text-darkPurple text-base font-semibold">
        Add Comment/Remark
      </p>
    </RightSideBar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import CardFooter from "@/components/CardFooter";
import RadioButton from "@/components/RadioButton";

export default {
  name: "ViewPayrunCycle",
  components: {
    Breadcrumb,
    BackButton,
    Card,
    Button,
    RightSideBar,
    CardFooter,
    RadioButton,
    Table
  },
  data() {
    return {
      openModal: false,
      loadingData: false,
      status: "",
      year: "",
      paymentType: "",
      ActionType: "Approve pay run",
      categoryArray: [
        {
          name: "Approve pay run",
          value: "Approve pay run",
          radioName: "job"
        },
        {
          name: "Reject pay run",
          value: "Reject pay run",
          radioName: "job"
        },
        {
          name: "Return for review",
          value: "Return for review",
          radioName: "job"
        }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll"
        },
        {
          disabled: false,
          text: "Payrun",
          id: "Payrun"
        },
        {
          disabled: false,
          text: "February 2021 Pay Cycle",
          id: "February 2021 Pay Cycle"
        }
      ],
      headers: [
        { title: "Employee Name", value: "name", width: 10 },
        { title: "Function", value: "function" },
        { title: "Regular Earnings", value: "regularEarnings" },
        {
          title: "Variable earnings (Ngn)",
          value: "variableEarnings",
          width: 10
        },
        { title: "Gross earnings (Ngn)", value: "grossEarnings", width: 10 },
        {
          title: "pension contribution (ngn)",
          value: "pensionContribution",
          width: 10
        },
        { title: "PAYE", value: "monthlyPAYE" },
        { title: "Net Pay", value: "netPay" },
        { title: "status", value: "status" },
        { title: "", value: "id" }
      ],
      tableData: [
        {
          name: "Felix Agu",
          function: "Technology",
          regularEarnings: "₦ 11,762,460.89",
          variableEarnings: "₦ 11,762,460.89",
          grossEarnings: "₦ 11,762,460.89",
          pensionContribution: "₦ 198,185.26",
          monthlyPAYE: "₦ 13,652,460.89",
          netPay: "₦ 13,652,460.89",
          status: "Partial",
          id: true
        }
      ]
    };
  },
  computed:{
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.partial {
  background: rgba(233, 147, 35, 0.08);
  color: rgba(233, 147, 35, 1);
}
</style>
